import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select'
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ['select']

  connect() {
    new TomSelect(this.selectTarget, {
      valueField: 'id',
      labelField: 'full_name',
      searchField: ['full_name'],
      load: (query, callback) => this.search(query, callback)
    })
  }

  async search(query, callback) {
    const response = await get(
      '/customer/drivers?q[search_by_full_name_or_email_or_phone]=' +
        encodeURIComponent(query),
      {
        responseKind: 'json'
      }
    )

    if (response.ok) {
      callback(await response.json)
    } else {
      callback()
    }
  }
}
